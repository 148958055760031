<template>
  <page-list noMarginFlag="false">
    <template #header>
      <div class="title">商品销量</div>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline="true">
        <el-form-item label="时间" prop="activityTime">
          <el-date-picker
            v-model="form.activityTime"
            type="daterange"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            range-separator=" - "
            popper-class="tpc"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            @change="shopChangeHandle"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" prop="channelId">
          <el-select
              v-model="form.channelId"
              placeholder="请选择"
              @change="channelOptions"
              :disabled="!form.shopId"
              clearable
              filterable
            >
              <el-option
                v-for="(item, index) in channelOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="所属店员：" prop="employeeId">
          <el-select
            v-model="form.employeeId"
            placeholder="请选择"
            :disabled="!form.shopId"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in employeeOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="listByPage()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetHandle()">重 置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="exportHandle()">导 出</el-button>
        </el-form-item>
      </el-form>
    </template>
    
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="分类查询" name="listSaleData">
        <goods-info-panel ref="listSaleData" :form="form"></goods-info-panel>
      </el-tab-pane>
      <el-tab-pane label="汇总查询" name="listSummarySaleData">
        <goods-stat-info-panel ref="listSummarySaleData" :form="form"></goods-stat-info-panel>
      </el-tab-pane>
    </el-tabs>
  </page-list>
</template>

<script>
import { listSaleData,genBill,listSummarySaleData,exportForm } from "@/apis/unidrink/dashboard";
import { getShopOptions } from "@/apis/unidrink/shop";
import { getChannelOptions } from "@/apis/unidrink/channel";
import { listAll } from "@/apis/unidrink/employee";
import { msgErr, msgSuc } from "@/helpers/message";
import goodsInfoPanel from "./goodsInfoPanel";
import goodsStatInfoPanel from "./goodsStatInfoPanel";
import { cloneDeep } from "lodash";
import Sortable from "sortablejs";

export default {
  components: {goodsInfoPanel,goodsStatInfoPanel},
  props: {
    mode: {
      type: String,
      default: "view" // view-页面；component-组件
    }
  },
  data() {
    return {
      activeName:"listSaleData",
      tableData: [],
      paginationData: {},
      initForm: {
        shopInfo:{},
        channelInfo:{},
        activityTime: this.getActivity()
      },
      form: {},
      total: 0,
      loading: false,
      dialogVisible: false,
      shopOptions: [],
      employeeOptions: [],
      channelOptions: [],
      editMode: "add",
      editData: {}
    };
  },
  emits: ["choose"],
  created() {
    this.form = cloneDeep(this.initForm);
    this.timeChangeHandle();
    getShopOptions(this.shopOptions);
  },
  mounted() {
    if (this.mode == "component") {
      this.listByPage();
    }
  },
  watch:{
    "form.activityTime"(value){
      this.timeChangeHandle();
    }
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.timeChangeHandle();
    this.listByPage();
  },
  methods: {
    timeChangeHandle(){
      if(this.form.activityTime&&this.form.activityTime.length>=2){
          this.form.beginTime=parseInt(this.$moment(this.form.activityTime[0]).format('X'));
          this.form.endTime=parseInt(this.$moment(this.form.activityTime[1]).format('X'))+24*3600;
      }else{
        this.form.beginTime="";
          this.form.endTime="";
      }
    },
    listByPage() {
      this.loading = true;
      let listByPage = this.activeName == "listSummarySaleData" ? this.$refs.listSummarySaleData:this.$refs.listSaleData;
      if(listByPage&&listByPage.getList){
        listByPage.getList(this.form);
      }
      // listSummarySaleData({ ...this.form, ...this.paginationData })
      //   .then(({ code, result, message }) => {
      //     if (code !== "0000") {
      //       msgErr(message);
      //       return;
      //     }
      //     const { list, total } = result;
      //     this.tableData = list;
      //     this.total = total;
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    resetHandle() {
      //重置表单
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },
    exportHandle(){
      this.loading = true;
      exportForm({ ...this.form })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { path } = result;
          window.open(path, '_blank');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 选择门店
     * @param {Object} item
     */
    chooseShopInfoConfirm(item) {
      this.form.shopInfo = item;
      this.form.shopId = item.id;
    },
    /**
     * 选择渠道
     * @param {Object} item
     */
    chooseChannelConfirm(item) {
      this.form.channelInfo = item;
      this.form.channelId = item.id;
    },
    handleClick(){
      this.listByPage();
    },
    //获取当天0点
    getActivity(){
      let today = new Date();
      let yesterday = new Date(today.getTime()-24*3600*1000);
      return  [this.format(yesterday),this.format(today)];
    },
    format(date){
      var year = date.getFullYear();
      var month = (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1));
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      // yyyy-MM-dd
      var now = [year,month,day].join('-');
      return now;
    },

    shopChangeHandle(){
      this.getEmployeeOptions();
      this.getChannelOptions();
    },

    getChannelOptions(){
      this.form.channelId = "";
      this.channelOptions=[];
      if(this.form.shopId){
        getChannelOptions(this.channelOptions, {shopId:this.form.shopId},false);
      }else{
        getChannelOptions(this.channelOptions, {},false);
      }
    },

    getEmployeeOptions(){
      this.form.employeeId = "";
      this.employeeOptions=[];
      if(this.form.shopId){
        listAll({shopId:this.form.shopId}).then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.employeeOptions.push(...list.map((row) => {
            return {
              name: row.name.replace(/[&nbsp;|└]/g, ""),
              id: row.id,
            };
          }));
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 15px;
  font-size: 24px;
}
</style>
